import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const JobPostTemplate = ({ data: { previous, next, job } }) => {
  return (
    <div>
      <Seo
        title={job.title}
        description={job.seo?.metaDesc}
      />

      <Layout jobsFooter={true} jobId={job.jobs.jobId}>
        <section className="intro_text jobs_intro">
          <div className="container">
            <a className="btn jobs_intro__return" href="/jobs">
              BACK TO JOBS {job.seo?.title}
            </a>
            <div className="section__title">{job.title}</div>
            <div className="jobs_intro__holder">
              <div className="jobs_intro__info">
                <div>Location: {job.jobs.location.name}</div>
                <div>Type: {job.jobs.jobType}</div>
                <div>Salary: {job.jobs.salary}</div>
                <div>
                  Consultant:{" "}
                  <a
                    href={
                      "/about/#team" +
                      job.jobs.consultant.name.replace(/ /g, "")
                    }
                  >
                    {job.jobs.consultant.name}
                  </a>
                </div>
                <div>ID: {job.jobs.jobId}</div>
              </div>
            </div>
            <div className="intro_text__holder">
              <div dangerouslySetInnerHTML={{ __html: job.content }} />
            </div>
          </div>
        </section>
        <Helmet>
          <script
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/19906557.js"
          />
        </Helmet>
      </Layout>
    </div>
  )
}

export default JobPostTemplate

export const pageQuery = graphql`
  query JobPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    job: wpJob(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      uri
      seo {
        metaDesc
        title
      }

      jobs {
        title {
          id
          name
        }
        salary
        location {
          id
          name
        }
        jobId
        jobType
        consultant {
          name
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpJob(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
